import React, { createContext, useContext, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Toast, ToastType, ToastPosition } from './Toast';
import styles from './Toast.module.css';

interface ToastContextType {
    addToast: (message: string, type: ToastType, position?: ToastPosition, duration?: number) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};

interface ToastData {
    id: string;
    message: string;
    type: ToastType;
    position: ToastPosition;
    duration?: number;
}

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [toasts, setToasts] = useState<ToastData[]>([]);

    const addToast = (message: string, type: ToastType, position: ToastPosition = 'top-right', duration = 3000) => {
        const id = Math.random().toString(36).substring(2, 9);
        const newToast: ToastData = { id, message, type, position, duration };
        setToasts((prevToasts) => [...prevToasts, newToast]);
    };

    const removeToast = (id: string) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };

    // Filter toasts by position
    const renderToastsByPosition = (position: ToastPosition) => {
        return (
            <AnimatePresence>
                {toasts
                    .filter((toast) => toast.position === position)
                    .map((toast) => (
                        <Toast
                            key={toast.id}
                            id={toast.id}
                            message={toast.message}
                            type={toast.type}
                            position={toast.position}
                            duration={toast.duration}
                            onClose={removeToast}
                        />
                    ))}
            </AnimatePresence>
        );
    };

    return (
        <ToastContext.Provider value={{ addToast }}>
            {children}
            <div className={styles.toastContainer}>
                <div className={styles.topLeft}>{renderToastsByPosition('top-left')}</div>
                <div className={styles.topRight}>{renderToastsByPosition('top-right')}</div>
                <div className={styles.bottomLeft}>{renderToastsByPosition('bottom-left')}</div>
                <div className={styles.bottomRight}>{renderToastsByPosition('bottom-right')}</div>
                <div className={styles.topCenter}>{renderToastsByPosition('top-center')}</div>
                <div className={styles.bottomCenter}>{renderToastsByPosition('bottom-center')}</div>
                <div className={styles.center}>{renderToastsByPosition('center')}</div>
            </div>
        </ToastContext.Provider>
    );
};
