'use client';

import React, { useEffect, useState } from "react";
import Link from 'next/link';
import { Logo } from "./Logo";
import styles from "./nav.module.css";

export default function Nav() {
    const [isMounted, setIsMounted] = useState(false);
    const [click, setClick] = useState(false);

    useEffect(() => {
        setIsMounted(true);
        return () => setIsMounted(false);
    }, []);

    if (!isMounted) return null;

    return (
        <nav className={`${styles.navbar} ${styles.fixed} ${styles.blur} ${click ? `${styles.active}` : ""}`} onClick={e => e.stopPropagation()}>
            <div className={styles.navbarContainer}>
                <div className={styles.navbarInner}>
                    <div className={styles.navLogo}>
                        <Link href="/">
                            <Logo color={"#FFF"}/>
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    );
}
