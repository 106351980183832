'use client';

import {ReactNode, useCallback, useEffect} from "react";
import {useRouter} from "next/navigation";
import {Amplify} from 'aws-amplify';
import {Hub, parseAmplifyConfig} from "aws-amplify/utils";
import {AuthSession, fetchAuthSession, FetchUserAttributesOutput} from "aws-amplify/auth";
import {deleteCookie, getCookie} from "cookies-next";
import {getTokenFromCookie} from "@/components/utils/client-side";
import {useStore} from "@/components/Hooks/StorageProvider";
import {AuthUser} from "@/components/types/auth";
import outputs from '@/amplify_outputs.json';

Amplify.configure(outputs, {
    ssr: true,
});

const amplifyConfig = parseAmplifyConfig(outputs);

export const AmplifyProvider = ({ children }: { children: ReactNode }) => {
    const router = useRouter();

    const setUser = useStore((state) => state.setUser);
    const setIsSignedIn = useStore((state) => state.setIsSignedIn);
    const resetAll = useStore((state) => state.reset);

    const getUserSession = useCallback(async (): Promise<AuthSession | null> => {
        try {
            const userPoolClientId = amplifyConfig?.Auth?.Cognito?.userPoolClientId;

            if (userPoolClientId) {
                // @ts-ignore
                return getTokenFromCookie(userPoolClientId);
            }

            return await fetchAuthSession();
        } catch (e) {
            console.error(e);
            // @ts-ignore
            if (e?.message === 'User does not exist.') {
                console.log('User does not exist. Resetting all states.');
                resetAll();
                return null;
            }
        }

        return null;
    }, [resetAll]);

    const getUserAttributes = useCallback(async (): Promise<FetchUserAttributesOutput | null> => {
        try {
            const session = await getUserSession();
            return {
                // @ts-ignore
                email: session?.idToken?.email,
                // @ts-ignore
                email_verified: session?.idToken?.email_verified,
                // @ts-ignore
                identities: session?.idToken?.identities,
                // @ts-ignore
                sub: session?.idToken?.sub
            };
        } catch (e) {
            // @ts-ignore
            if (e?.message === 'User does not exist.') {
                console.log('User does not exist. Resetting all states.');
                resetAll();
                return null;
            }
        }

        return null;
    }, [resetAll]);

    const checkIfUserIsSignedIn = useCallback(async () => {
        try {
            const session = await getUserSession();
            const userAttributes = {
                // @ts-ignore
                email: session?.idToken?.email,
                // @ts-ignore
                email_verified: session?.idToken?.email_verified,
                // @ts-ignore
                identities: session?.idToken?.identities,
                // @ts-ignore
                sub: session?.idToken?.sub
            }

            const user: AuthUser = {
                // @ts-ignore
                loginId: session?.idToken?.email || null,
                attributes: userAttributes || undefined,
                // @ts-ignore
                groups: session?.tokens?.accessToken?.payload?.['cognito:groups'] || [],
            };

            if (userAttributes?.email) {
                // @ts-ignore
                let ko = window.ko;
                ko?.identify?.(user?.loginId);
            }

            setUser(user);
            setIsSignedIn(true);
        } catch (e) {
            console.error(e);
            setUser(null);
            setIsSignedIn(false);
        }
    }, [getUserSession, setIsSignedIn, setUser]);

    const handleAuthEvents = useCallback(async ({ payload: { event, data } }: any) => {
        console.log('event', event, data);
        switch (event) {
            case 'autoSignIn':
            case 'signedIn':
                console.log('user have been signedIn successfully.');

                await checkIfUserIsSignedIn();

                // check cookies for 'redirect-post-login' and redirect if it exists
                const redirectUrl = getCookie('redirect-post-login');
                if (redirectUrl) {
                    // remove the cookie
                    deleteCookie('redirect-post-login');
                    router.replace(redirectUrl);
                    return;
                } else {
                    router.replace('/dashboard');
                }

                break;
            case 'signedOut':
                console.log('user have been signedOut successfully.');

                setUser(null);
                setIsSignedIn(false);

                // @ts-ignore
                let ko = window.ko;
                ko?.reset?.();

                router.replace('/');

                break;
            case 'tokenRefresh':
                console.log('auth tokens have been refreshed.');

                await checkIfUserIsSignedIn();

                break;
            case 'tokenRefresh_failure':
                console.log('failure while refreshing auth tokens.');
                break;
            case 'signInWithRedirect':
                console.log('signInWithRedirect API has successfully been resolved.');

                await checkIfUserIsSignedIn();

                break;
            case 'signInWithRedirect_failure':
                console.log('failure while trying to resolve signInWithRedirect API.');
                break;
            case 'customOAuthState':
                console.log('custom state returned from CognitoHosted UI');
                break;
            default:
                console.log('unhandled event', event);
                break;
        }
    }, [checkIfUserIsSignedIn, setIsSignedIn, setUser]);

    useEffect(() => {
        const hub = Hub.listen('auth', handleAuthEvents);

        checkIfUserIsSignedIn();

        return () => {
            hub();
        }
    }, [checkIfUserIsSignedIn, handleAuthEvents]);

    return children;
}
